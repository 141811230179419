//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            newsPromotions: [],
            server_errors: {
                title: '', description: '', imageFile: ''
            },
            news: {},
            image: "",
            imageUrl: "",
            //Pagination
            offset: 15,
            pagination: {},
            per_page: 12,
            headers: [{
                text: "Id", align: "start", sortable: false, value: "id",
            },
                {
                text: "Title", align: "start", sortable: false, value: "title",
            },
                {
                    text: "ລະຫັດສ່ວນຫຼຸດ", align: "start", sortable: false, value: "promotion_code",
                },
                {
                    text: "Status", align: "start", sortable: false, value: "status",
                },
                {text: "Image", value: "image"},
                {
                    text: "Created", align: "start", sortable: false, value: "created_at",
                },
                {text: "Actions", value: "actions", sortable: false},
            ],
            nameRules: [v => !!v || 'Title is required', v => (v && v.length >= 2) || 'Name must be less than 2 characters',],
            descriptionRules: [(v) => !!v || "Description is required"],
            toast: {
                value: true, color: 'success', msg: 'Success'
            },
            toast_error: {
                value: true, color: 'error', msg: 'Something when wrong!'
            }
        }
    }, methods: {
        fetchData() {
            this.TableLoading = true;
            this.$admin.get('news/promotions',
                {
                    params: {
                        page: this.pagination.current_page,
                        per_page: this.per_page,
                    }
                }).then(res => {
                this.newsPromotions = res.data.data;
                this.pagination = res.data.data;
                this.TableLoading = false;
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        AddData() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },

        onFileChange(e) {
            let input = e.target;
            let file = e.target.files[0];
            this.image = input.files[0];
            this.imageUrl = URL.createObjectURL(file);
            this.server_errors.imageFile = '';
            let formData = new FormData();
            formData.append("imageFile", this.image);
            this.$axios.post('/upload-image', formData)
                .then(res => {
                    if (res.status == 200) {
                        this.image = res.data.fileName;
                    }
                }).catch(error => {
                this.image = '';
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });

        },

        Submit() {
            // let formData = new FormData();
            this.loading = true;
            // formData.append("title", this.news.title);
            // formData.append("description", this.news.description);
            // formData.append("feed", 0);
            // formData.append("image", this.image);

            const data = {
                title:this.news.title,
                promotion_code: this.news.promotion_code,
                description: this.news.description,
                image:this.image,
            }

            this.$admin.post('/news/promotion/add', data)
                .then(res => {
                    if (res.status == 200) {
                        setTimeout(() => {
                            this.loading = false;
                            this.news = {},
                                this.Close();
                        }, 300);
                    }
                }).catch(error => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast_error);
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });
        },

        updateNewsPromotion(id) {
            this.$router.push({name: 'UpdateNewsPromotion', params: {id: id}});
        },

        createNewsPromotion() {
            this.$router.push({name: 'CreateNewsPromotion'});
        }, Close() {
            this.$router.go(-1);
        },

        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },

        deleteItem(id) {
            this.dataID = id;
            this.$store.commit("modalDelete_State", true);
        }, deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('news/promotion/delete/' + this.dataID).then(res => {
                if (res.status == 200) {
                    setTimeout(() => {
                        this.fetchData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 300);
                }
            }).catch(() => {
                this.$store.commit("modalDelete_State", false);
                this.loading = false;
            })
        },
        setStatus(status){
            if(status == 'open'){
                return 'success'
            } else if(status == 'close'){
                return 'error'
            }
        }
    }, created() {
        this.fetchData();
    },
    watch: {
        onFileChange: function () {
            this.server_errors.imageFile = '';
        }
    }
}
