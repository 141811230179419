var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" ຂ່າວສານ Promotion "),_c('span',{staticClass:"primary-color"},[_vm._v("("+_vm._s(_vm.newsPromotions.length)+")")])])]),_c('v-col',{staticClass:"d-flex justify-end"},[[_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"btn-primary",attrs:{"fab":"","small":"","dark":""},on:{"click":function($event){return _vm.createNewsPromotion()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]],2)],1),_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.newsPromotions,"items-per-page":15,"loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait","disable-pagination":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36px"}},[(item.image)?_c('img',{attrs:{"src":("" + (item.image))}}):_vm._e()])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.setStatus(item.status)}},[_vm._v(_vm._s(item.status))])]}},{key:"item.promotion_code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"info--text"},[_vm._v(_vm._s(item.promotion_code))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format('DD-MM-YYYY'))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.updateNewsPromotion(item.id)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('br'),(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchData()}}}):_vm._e()],1)],1),_c('ModalDelete',[[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDelete()}}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK ")]),_c('v-spacer')],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }